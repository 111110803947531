.pagination {
  margin: 2rem 0;

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    line-height: 1;

    li {
      list-style-type: none;
      margin: .5rem;

      &.active a {
        background: $primary;
        color: $white;
      }
      
      a {
        display: inline-block;
        padding: .75rem 1rem;
        border-radius: 100%;
        background: $light-gray;
        text-decoration: none;
        font-weight: bold;

        &:hover {
          background: darken($light-gray, 10%);
        }
      }
    }
  }
}