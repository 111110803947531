@font-face {
  font-family: 'HK Grotesk';
  src: url('../fonts/hkgrotesk-bold-webfont.woff2') format('woff2'),
       url('../fonts/hkgrotesk-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HK Grotesk';
  src: url('../fonts/hkgrotesk-regular-webfont.woff2') format('woff2'),
       url('../fonts/hkgrotesk-regular-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'hk_grotesksemibold_italic';
  src: url('../fonts/hkgrotesk-semibold-webfont.woff2') format('woff2'),
       url('../fonts/hkgrotesk-semibold-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

strong {
  font-weight: bold;
}