.list {
  padding: 0;
  margin: 0;

  li {
    display: block;
    list-style-type: none;

    a {
      display: block;
      text-decoration: none;
      padding: rem-calc(4 0);
      border-radius: 4px;

      &.active {
        background: $primary;
        color: darken($primary, 35%);
        padding: rem-calc(4 8);
      }
    }
  }
}