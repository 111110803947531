.gallery {
  &__item {
    margin-bottom: rem-calc(32);
  
    img {
      display: block;
      width: 100%;
    }
  }
}

#baguetteBox-overlay .full-image figcaption {
  @include breakpoint(tablet) {
    font-size: rem-calc(24);
  }
}