.button {
  position: relative;
  padding: rem-calc(16 24);
  display: inline-block;
  text-decoration: none;
  appearance: none;
  font-family: $global-font-family;
  color: $primary;
  border: 2px solid $primary;
  font-weight: bold;
  font-size: rem-calc(18);
  border-radius: 0.25rem;

  &:hover {
    color: $white;
    background: $primary;
    cursor: pointer;
  }

  &--white {
    color: $white;
    border-color: #dcdcdc;

    &:hover {
      background: $white;
      color: $black;
      border-color: #dcdcdc;
    }
  }

  &--small {
    font-size: rem-calc(13);
    padding: rem-calc(8 12);
  }
}
