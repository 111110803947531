form {
  fieldset {
    border: none;
    padding: 0;
  }

  legend {
    display: block;
    width: 100%;
    font-size: rem-calc(24);
    margin-bottom: rem-calc(24);

    &:after {
      content: '';
      height: rem-calc(1);
      width: 85%;
      background-color: #c2c2c2;
      display: block;
    }
  }

  input,
  textarea {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    border-color: rgba(160, 174, 192, 1);
    border-radius: 0.25rem;
  }

  .inline-field {
    display: inline-flex;
    min-width: 8rem;

    input[type='number'] {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
    }

    .button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      font-size: rem-calc(13);
      padding: rem-calc(4 8);
    }
  }
}
