.subscribe {
  display: flex;
  justify-content: space-between;

  &__input {
    width: calc(100% - 140px);
    border: 2px solid #dcdcdc;
    border-right: 0;
    background: none;
  }

  &__button {
    border-radius: 0;
    background: none;
    -webkit-appearance: none;
    color: $white;
    font-family: $global-font-family;
    padding: rem-calc(0 30);
    width: 140px;
    border: 2px solid #dcdcdc;
  }
}