.feature {
  margin-bottom: rem-calc(32);

  h2 {
    font-size: rem-calc(24);
    margin-bottom: rem-calc(24);

    &:after {
      content: '';
      height: rem-calc(1);
      width: 85%;
      background-color: #c2c2c2;
      display: block;
    }
  }

  h2, h3 {
    a {
      color: $black;
      text-decoration: none;
    }
  }

  img {
    margin-bottom: rem-calc(8);
  }
}