.menu {
  padding: 0;
  margin: 0;

  li {
    list-style-type: none;
    display: inline-block;

    a {
      padding: rem-calc(8);
      display: block;
      text-decoration: none;
    }
  }

  &--center {
    text-align: center;
  }

  &--right {
    text-align: right;
  }
}