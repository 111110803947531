@import "settings";

@import "../../node_modules/alcedo/scss/style";
@import '../../node_modules/baguettebox.js/dist/baguetteBox.min';

@include alcedo-grid;
@include alcedo-type;
@include alcedo-table;
@include alcedo-links;
@include alcedo-forms;
@include alcedo-image;
@include alcedo-embed;

@import "components/actionbox";
@import "components/archive";
@import "components/article";
@import "components/button";
@import "components/callout";
@import "components/cart";
@import "components/feature";
@import "components/field";
@import "components/gallery";
@import "components/hero";
@import "components/list";
@import "components/menu";
@import "components/options";
@import "components/pagination";
@import "components/product";
@import "components/section";
@import "components/subscribe";
@import "components/stockist";
@import "components/tabs";
@import "components/testimonial";

@import "global/header";
@import "global/forms";
@import "global/footer";
@import "global/typography";

@import "utility/gap";
@import "utility/text-align";

@import "main";

.product-listing {
  h2 {
    font-size: rem-calc(18);
    margin: rem-calc(10 0);
  }
}

h5 span, h5 strong {
//   font-family: $body-font-family;
  text-transform: uppercase;
  letter-spacing: .2em;
  color: $primary;
  display: block;
}

article.main {
  img {
    margin-bottom: rem-calc(20);
  }
}