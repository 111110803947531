.stockist {
  padding-bottom: rem-calc(16);
  height: 100%;

  &__inner {
    background: $light-gray;
    height: 100%;
    padding: rem-calc(16);

    @include breakpoint(tablet) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__details {
    flex: 1;

    @include breakpoint(tablet) {
      padding: rem-calc(0 32);
    }

    h5 {
      margin: 0;
      font-weight: bold;
      font-size: rem-calc(18);
    }

    p {
      margin: 0;
    }
  }

  img {
    margin: 0;
    width: rem-calc(64);
    margin-bottom: rem-calc(24);

    @include breakpoint(tablet) {
      width: rem-calc(120);
      margin-bottom: 0;
    }
  }

  .list {
    @include breakpoint(tablet) {
      min-width: rem-calc(160);
      text-align: right;
    }
  }
}