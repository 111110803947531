.product {
  color: $white;

  &__header {
    padding: rem-calc(64 0);

    p {
      @include breakpoint(tablet) {
        font-size: rem-calc(24);
      }
    }

    img {
      border-radius: 4px;
    }
  }

  &__details {
    padding: rem-calc(32 0);

    a {
      color: inherit;
    }
  }

  &--store-and-thaw {
    background: #1A1A1A;
  }

  &--heatwave {
    background: $heatwave-background;
    color: $heatwave-foreground;

    .button {
      color: $heatwave-foreground;
      border-color: $heatwave-foreground;

      &:hover {
        background: $heatwave-foreground;
        color: $heatwave-background;
      }
    }
  }

  &--heatwave-teatguard {
    background: $teatguard-background;
    color: $teatguard-foreground;

    .button {
      color: $teatguard-foreground;
      border-color: $teatguard-foreground;

      &:hover {
        background: $teatguard-foreground;
        color: $teatguard-background;
      }
    }
  }

  &--frisky-lamb-warmer {
    background: $frisky-lamb-warmer-background;
    color: $frisky-lamb-warmer-foreground;

    .button {
      color: $frisky-lamb-warmer-foreground;
      border-color: $frisky-lamb-warmer-foreground;

      &:hover {
        background: $frisky-lamb-warmer-foreground;
        color: $frisky-lamb-warmer-background;
      }
    }
  }

  &--cow-collars {
    background: $cow-collars-background;
    color: $cow-collars-foreground;

    .button {
      color: $cow-collars-foreground;
      border-color: $cow-collars-foreground;

      &:hover {
        background: $cow-collars-foreground;
        color: $cow-collars-background;
      }
    }
  }

  &--the-pyon-milk-agitator {
    background: $agitator-background;
    color: $agitator-foreground;

    .button {
      color: $agitator-foreground;
      border-color: $agitator-foreground;

      &:hover {
        background: $agitator-foreground;
        color: $agitator-background;
      }
    }
  }

  &--milk-freighter {
    background: $milk-freighter-background;
    color: $milk-freighter-foreground;

    .button {
      color: $milk-freighter-foreground;
      border-color: $milk-freighter-foreground;

      &:hover {
        background: $milk-freighter-foreground;
        color: $milk-freighter-background;
      }
    }
  }
}