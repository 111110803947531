.testimonial {
  padding: rem-calc(16);

  blockquote {
    border-left: 4px solid $primary;
  }

  cite {
    font-weight: bold;
    color: $black;
    text-transform: uppercase;
    font-style: normal;
  }
}