.hero {
  position: relative;
  padding: rem-calc(240 0);
  background: $black;
  overflow: hidden;
  color: $white;

  &__video {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: 0.6;
  }

  .container {
    position: relative;
    z-index: 2;

    h1 {
      border-left: 4px solid currentColor;
      padding-left: rem-calc(24);
      line-height: 1;
      text-transform: uppercase;
      letter-spacing: .1em;
      margin-bottom: rem-calc(16);
    }

    h1 + p {
      text-transform: uppercase;
      letter-spacing: .1em;
      font-weight: bold;
      margin: 0;
      margin-left: rem-calc(24);
    }

    .button {
      margin-top: rem-calc(24);
      margin-left: rem-calc(24);
    }
  }

  &--home {
    background: url('../img/dist/home-intro-bg.jpg') center bottom / cover;
  }

  &--story {
    background: url('../img/dist/story-intro-bg.jpg') center bottom / cover;
  }

  &--blog {
    background: url('../img/dist/blog-intro-bg.jpg') center bottom / cover;
    padding: rem-calc(180 0 64);
  }

  &--stockists {
    background: url('../img/dist/stockists-intro-bg.jpg') center top / cover;
  }

  &--events {
    background: url('../img/dist/events-intro-bg.jpg') center bottom / cover;
  }

  &--contact {
    background: url('../img/dist/contact-intro-bg.jpg') center bottom / cover;
  }

  &--basket {
    background: url('../img/dist/cart-intro-bg.jpg') center bottom / cover;
    padding: rem-calc(180 0 64);
  }

  &--members {
    background: url('../img/dist/members-intro-bg.jpg') center bottom / cover;
    padding: rem-calc(180 0 64);
  }

  &--product {
    padding: rem-calc(180 0 64);
  }
}