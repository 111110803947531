.hide {
  display: none;
}

.finput {
  display: none;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.stick {
  position: sticky;
  top: rem-calc(32);
}

.visually-hidden {
  display: block;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(1px);
  white-space: nowrap;
  position: absolute;
}
