.action-box {
  padding: rem-calc(32);
  background: $white;
  color: $black;

  h1 {
    font-size: rem-calc(24);
    margin-bottom: rem-calc(24);

    &:after {
      content: '';
      height: rem-calc(1);
      width: 85%;
      background-color: #c2c2c2;
      display: block;
    }
  }
}