.field {
  margin-bottom: rem-calc(16);

  label {
    text-align: left;
    margin-bottom: rem-calc(4);
    display: block;
    font-weight: bold;
  }

  &__option {
    margin-bottom: rem-calc(16);

    input[type='checkbox'], input[type='radio'] {
      display: inline-block;
      margin-right: rem-calc(16);
    }

    label {
      display: inline-block;
      font-weight: 300;
    }
  }
}