.callout {
  text-align: center;
  padding: rem-calc(32 8);
  background: #5f1d16;

  h2, h3, h4, h5, p {
    margin: 0;
    color: $white;
  }

  a {
    display: inline-block;
    color: $white;
    text-decoration: none;
    border-bottom: 3px solid $white;
    margin-left: rem-calc(16);
  }

  &--alert {
    background: red;
  }
}