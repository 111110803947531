.footer {
  background: $black;
  padding: rem-calc(32 0);
  color: $white;

  h5 {
    color: $white;
    margin-bottom: rem-calc(24);

    &:after {
      content: '';
      height: rem-calc(1);
      width: 85%;
      background-color: #c2c2c2;
      display: block;
    }
  }

  p {
    color: $white;
    margin: 0;
    font-size: rem-calc(13);
  }

  ul {
    color: inherit;

    a {
      color: inherit;
    }
  }

  .button {
    color: inherit;
    border-color: $white;
  }

  &__bottom {
    margin-top: rem-calc(48);
  }

  &__disclaimer {
    text-align: center;
    margin-top: rem-calc(16);

    p {
      font-size: rem-calc(12);
    }
  }
}