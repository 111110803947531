.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 15;
  padding: rem-calc(16 0);

  @include breakpoint(tablet) {
    padding: rem-calc(24 0);
  }

  &__inner {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 1rem;
  }

  .logo {
    display: block;
    filter: brightness(0) invert(1);
    width: 25%;
    max-width: 10rem;
    margin-right: 2rem;
    flex: 1 1 0;

    svg {
      width: 100%;
    }
  }

  .basket {
    display: none;
    padding: rem-calc(16 0);

    @include breakpoint(tablet) {
      display: block;
      text-align: right;
      padding: 0;
      margin-top: rem-calc(4);
    }

    a {
      display: inline-block;
      color: $white;
      padding-bottom: rem-calc(4);
      font-size: rem-calc(13);
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: .1em;
      text-decoration: none;
      border-bottom: 2px solid $white;
    }
  }

  &__navigation {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include breakpoint(tablet) {
      align-items: flex-end;
      flex: 1 0 0;
      width: auto;
    }
  }

  .menu {
    position: relative;
    display: none;
    list-style-type: none;
    padding: 0;
    margin: 0;
    line-height: 1;

    @include breakpoint(tablet) {
      display: block;
      text-align: right;
    }

    li {
      display: block;

      @include breakpoint(tablet) {
        display: inline-block;
      }

      &.dropdown {
        a:after {
          content: '';
          display: inline-block;
          margin-left: rem-calc(4);
          width: rem-calc(10);
          height: rem-calc(10);
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid $light-gray;
          vertical-align: middle;
          opacity: 0.7;
        }
      }

      a {
        display: block;
        padding: rem-calc(12 0);
        font-size: rem-calc(12);
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: .1em;
        text-decoration: none;
        color: $white;
        transition: .4s all;

        @include breakpoint(tablet) {
          padding: rem-calc(4);
        }

        @include breakpoint(desktop) {
          padding: rem-calc(8);
        }

        &:hover {
          color: #fefefe;
          opacity: .6;
        }
      }

      > .menu {
        @include breakpoint(tablet) {
          display: none;
          visibility: hidden;
          position: absolute;
          text-align: left;
          background: $white;
          border: 1px solid $light-gray;
          max-width: rem-calc(240);
        }

        li {
          width: 100%;
          display: block;

          a {
            color: $dark-gray;
            padding: .75rem .5rem;

            &:hover {
              color: $black;
              background: $light-gray;
            }
          }
        }
      }

      &:hover {
        > .menu {
          visibility: visible;
          opacity: 1;
          display: block
        }
      }
    }
  }

  .menu--sub {
    border-bottom: 1px solid rgba($white, 0.1);
    order: 3;
    padding-bottom: .25rem;

    @include breakpoint(tablet) {
      order: 0;
    }
  }

  .menu--main {
    order: 2;
  }

  .menu--main > li > a {
    font-size: rem-calc(20);
    text-transform: none;
    letter-spacing: 0;
  }

  .toggle {
    margin-left: auto;

    &__button {
      position: relative;
      z-index: 0;
      display: inline-block;
      width: 24px;
      height: 24px;
      -webkit-appearance: none;
      outline: none;
      border: none;
      background: none;
      transition: all 0.5s;
      padding: 0;

      @include breakpoint(tablet) {
        display: none;
      }

      &:before, &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background: $white;
        display: block;
        transition: .25s all;
        top: 45%;
      }

      &:before {
        transform: translateY(-5px);
      }

      &:after {
        transform: translateY(5px);
      }
    }
  }

  &.is-open {
    background: $white;

    .logo {
      filter: none;
    }

    .menu {
      li a {
        color: $black;
      }
    }

    .basket a {
      color: $black;
      border-color: $black;
    }

    .toggle__button {
      &:before, &:after {
        background: $black;
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }

    .menu, .basket {
      display: block;
    }
  }
}
