.tabs {
  width: 100%;

  &__navigation {
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-x: auto;
    background: $white;

    a {
      margin: 0;
      flex-shrink: 0;
      padding: rem-calc(12 16);
      display: inline-block;
      text-decoration: none;
      font-family: $global-font-family;
      color: $black;
      font-weight: bold;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      margin-right: .25rem;
      margin-bottom: -1px;
      border: 1px solid transparent;
      color: $dark-gray;

      &.is-active {
        background: $white;
        color: $black;
        border: 1px solid $medium-gray;
      }
    }
  }

  &__panel {
    display: none;
    padding: rem-calc(16);
    background: $light-gray;
    border: 1px solid $medium-gray;

    &--active {
      display: block;
    }
  }
}