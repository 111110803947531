.archive {
  margin: 0;
  padding: 0;
  text-align: left;

  li {
    list-style-type: none;

    ul {
      padding: 0;
      margin-bottom: rem-calc(16);
    }
  }
}